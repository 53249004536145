import React, { useState, useContext, useEffect } from 'react';
import './AdminPage.css';
import './DebugPage.css';
import { api } from '../api';
import { DataContext } from '../DataContext';
import Croix from '../Croix.js';
import { pages } from './resources'

function DebugPage({ changeCurrentPage}) {
  const { data } = useContext(DataContext);


  //STATUT MAINTENANCE
  const [maintenanceStatus,setMaintenanceStatus]=useState(false);

  const handleMaintenance = async () => {
    const status = !maintenanceStatus; 

    try {
      const response = await api.post('/admin/maintenance/' + (status ? "true" : "false"), {});
      setMaintenanceStatus(status);
      console.log(response.data);
    } catch (error) {
      console.error('Erreur:', error);
    }
  };

  useEffect(() => {
    const fetchMaintenanceStatus = async () => {
      try {
        const response = await api.get('/maintenanceStatus'); // Appel à l'API
        setMaintenanceStatus(response.data.isMaintenanceMode); // Mettre à jour l'état
      } catch (error) {
        console.error("Erreur lors de la récupération du statut de maintenance", error);
      }
    };

    fetchMaintenanceStatus();
  },[]);


   //PAIEMENT

  const handlePayement = async () => {
    try {
      const res = await api.post("/test");
      console.log(res.data);
      const id = res.data.id;

      // Redirection vers la page de paiement avec l'ID dans l'URL
      window.location.href = '/paiementPage/' + id;
    } catch (error) {
      console.error("Erreur lors de la création du paiement:", error);
    }
  };
  

  //RENVOIE D'EMAIL

  const [showAlert, setShowAlert] = useState("");
  const [errorMessage, setErrorMessage] = useState('...');

  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [client, setClient] = useState(""); 

  const handleAlert = (message) => {
      setShowAlert(message);
  };

  const closeAlert = () => {
    setShowAlert("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();

    try {
      if (!lastName || !firstName) {
        setErrorMessage("Champs vide");
        return;
      }
      
      const res = await api.get('/admin/findClient/' + lastName +'/'+firstName);

      if (res.status === 200) {
        setErrorMessage('...');
        handleAlert("Email : "+ res.data.email);
        setClient(res.data)
        // Réinitialise les champs après succès
        setFirstName("");
        setLastName("");
      } else {
        handleAlert("Une erreur est survenue");
        console.error(res.status);
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data);
      }
    }
  };

  const sendBackEmail =  async (event) => {

    closeAlert();
    try {
        if (!client) {
          return;
        }
        
        const res = await api.post('/admin/sendBackEmail/' + client.id);
  
        if (res.status === 200) {
          setClient("")
          handleAlert("Email envoyé avec succès")
        } else {
          handleAlert("Une erreur est survenue");
          console.error(res.status);
        }
      } catch (error) {
        console.error(error);
        if (error.response && error.response.data) {
          setErrorMessage(error.response.data);
        }
      }
  };



  return (
    <div className="debugPage-main">
      {showAlert !== "" && <CustomAlert sendEmail ={sendBackEmail} message={showAlert} onClose={closeAlert} />}

      <header className="debug-header">
        <button className='Bouton-Retour' onClick={() => changeCurrentPage(pages.HOMEPAGE)} alt='retour' id="homePage-return">
          {"↖"}
        </button>
        <h1 className='ListPage-h1'>Outils de débugage</h1>
        <Croix />
      </header>

      <div className='debug-button-div'>
        <button onClick={handleMaintenance} className={maintenanceStatus?'HomePage-Button activBoutton':'HomePage-Button'} >{maintenanceStatus?"Déactiver le mode maintenance":"Activer le mode maintenance"}</button>
        <button onClick={handlePayement} className='HomePage-Button' >Test paiement</button>
      </div>

      {data && 
        <main className="debug-main">
          <h2 className="debug-h2">Renvoie d'email</h2>
          <form onSubmit={handleSubmit} className="debug-form">
            <input 
              id="inscrit-first" 
              placeholder="Prénom" 
              className="debug-input" 
              value={firstName} 
              onChange={(e) => setFirstName(e.target.value)} 
            />
            <input 
              id="inscrit-last" 
              placeholder="Nom" 
              className="debug-input" 
              value={lastName} 
              onChange={(e) => setLastName(e.target.value)} 
            />
            <div className="debug-error-message" style={{ opacity: errorMessage === '...' ? 0 : 1 }}> 
              {errorMessage} 
            </div>
            <button type="submit" className="debug-button"> Valider </button>  
          </form>
        </main>
      }
    </div>
  );
}

function CustomAlert({ sendEmail, message, onClose}) {
  return (
    <div className="custom-alert-overlay">
      <div className="custom-alert-box">
        <p>{message}</p>
        <button className="AdminPage-button-add" onClick={sendEmail}>Envoyer email</button>
        <button className="AdminPage-button-valid" onClick={onClose}>Annuler</button>
      </div>
    </div>
  );
}

export default DebugPage;

