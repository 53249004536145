import React, { useState,useContext } from 'react';
import './LogoAnnim.css'
import { DataContext } from './DataContext';

const LogoAnnim = () => {

  const { data } = useContext(DataContext);

  const circlesData = [
    // Cercles du groupe B
    { cx: 197.56, cy: 282.38 },
    { cx: 197.56, cy: 316.19 },
    { cx: 265.18, cy: 316.19 },
    { cx: 231.37, cy: 282.38 },
    { cx: 231.37, cy: 350 },
    { cx: 231.37, cy: 417.62 },
    { cx: 197.56, cy: 350 },
    { cx: 197.56, cy: 383.81 },
    { cx: 265.18, cy: 383.81 },
    { cx: 197.56, cy: 417.62 },
    // Cercles du groupe E
    { cx: 468.63, cy: 282.38 },
    { cx: 468.63, cy: 316.19 },
    { cx: 502.44, cy: 282.38 },
    { cx: 502.44, cy: 350 },
    { cx: 502.44, cy: 417.62 },
    { cx: 468.63, cy: 350 },
    { cx: 468.63, cy: 383.81 },
    { cx: 468.63, cy: 417.62 },
    // Cercles du groupe D
    { cx: 333.27, cy: 282.38 },
    { cx: 333.27, cy: 316.19 },
    { cx: 400.9, cy: 316.19 },
    { cx: 367.08, cy: 282.38 },
    { cx: 400.9, cy: 350 },
    { cx: 367.08, cy: 417.62 },
    { cx: 333.27, cy: 350 },
    { cx: 333.27, cy: 383.81 },
    { cx: 400.9, cy: 383.81 },
    { cx: 333.27, cy: 417.62 },
    // Autres cercles
    { cx: 350, cy: 113.97 },
    { cx: 350, cy: 161.29 },
    { cx: 373.66, cy: 137.63 },
    { cx: 326.34, cy: 137.63 },
    { cx: 350, cy: 538.71 },
    { cx: 350, cy: 586.03 },
    { cx: 373.66, cy: 562.37 },
    { cx: 326.34, cy: 562.37 },
    { cx: 662.12, cy: 350 },
    { cx: 614.8, cy: 350 },
    { cx: 638.46, cy: 373.66 },
    { cx: 638.46, cy: 326.34 },
    { cx: 85.2, cy: 350 },
    { cx: 37.88, cy: 350 },
    { cx: 61.54, cy: 373.66 },
    { cx: 61.54, cy: 326.34 },
];


  const initialRadius = 23.66;
  const initialColor = 'white';

  const [circleRadius, setCircleRadius] = useState(Array(28+16).fill(initialRadius));
  const [circleColor, setCircleColor] = useState(Array(28+16).fill(initialColor));
  const [textSize,setTextSize]= useState ("35px");
  const [textColor,setTextColor]=useState(initialColor)

  function couleurAvecTransparence() {
    const couleurHex=data.color
    // Conversion de la couleur hexadécimale en valeurs RGB
    const r = parseInt(couleurHex.substring(1, 3), 16);
    const g = parseInt(couleurHex.substring(3, 5), 16);
    const b = parseInt(couleurHex.substring(5, 7), 16);
  
    // Retourne la couleur avec la transparence de 75%
    return `rgba(${r}, ${g}, ${b}, 0.75)`;
  }

  const handleMouseOver = (index) => {
    const updatedRadius = [...circleRadius];
    updatedRadius[index] = 35;
    setCircleRadius(updatedRadius);
    setTimeout(handleMouseOut,500);
  };

  const handleMouseOut = (index) => {
    setCircleRadius(Array(28+16).fill(initialRadius));
  };

  const handleClick = (index) => {
    const updatedColor = [...circleColor];
    const randomColor = couleurAvecTransparence();
    updatedColor[index] = randomColor;
    setCircleColor(updatedColor);
  };


  const handleMouseOverText = () => {
    setTextSize("40px");
    setTimeout(handleMouseOut,500);
  };

  const handleMouseOutText = () => {
    setTextSize("35px");
  };

  const handleClickText = () => {
    const randomColor = couleurAvecTransparence();
    setTextColor(randomColor);
  };

  return (
    <svg
      className='logoAnnim-SVG'
      version="1.1"
      xmlns="http://www.w3.org/2000/svg"
      xmlnsXlink="http://www.w3.org/1999/xlink"
      viewBox="0 0 700 700"
    >
      <g id="circles">
        {circlesData.map((circle, index) => (
          <circle className='circles'
            key={index}
            cx={circle.cx}
            cy={circle.cy}
            r={circleRadius[index]}
            fill={ circleColor[index]}
            onMouseOver={() => handleMouseOver(index)}
            onMouseLeave={() => handleMouseOut(index)}
            onClick={() => handleClick(index)}
          />
        ))}
      </g>
      <text className="text"
        transform="translate(350 490.85)"
        onMouseOver={() => handleMouseOverText()}
        onMouseLeave={() => handleMouseOutText()}
        onClick={() => handleClickText()}
        style ={{
          "fill": textColor,
          "font-size": textSize,
          "textAnchor": "middle"
        }}>
        <tspan x="0" y="0">ENSCI - Les Ateliers</tspan>
      </text>
    </svg>
  );
};

export default LogoAnnim;

