import React from 'react';
import {api} from '../api'
import './AdminPage.css';


function GestionBDD({handleAlert}) {

    const handleRefreshList = async ()=> {
        try {
            const res = await api.get("enscien/refreshList")
            handleAlert(res.data); 
        } catch (error) {
            handleAlert("Erreur lors de la récupération de la liste");
        }
    };

    const handleCleanDatabase = async () => {
        try {
        const response = await api.post('/admin/formulaire/cleanDataBase');
        handleAlert(response.data); 
        } catch (error) {
        handleAlert("Erreur lors de la supression de la base de données");
        }
    };

    return (
        <div className="Formulaire-form">
            <div className='bdd-div'> 
                <p id="bdd-p1">Mettre à jour la liste permet de récupérer la dernière liste des élèves inscrits à l'ENSCI. <br/><br/>C'est une action à faire avant chaque soirée ou après chaque début de semestre pour être sur que la liste contienne bien tous les élèves.</p>
                <button id="bdd-b1" className='AdminPage-button-add' onClick={handleRefreshList} >Mettre à jour la liste</button>
                <div className='separateur-h'/>
                <p id="bdd-p2">Vider la base de données va supprimer tous les inscrits à l'événement en cours (ou passé). <br/><br/>Cette action est irréversible. <br/>A NE PAS FAIRE PENDANT un évènement mais A FAIRE AVANT le début d'un nouveau.</p>
                <button id="bdd-b2" className='AdminPage-button-empty' onClick={handleCleanDatabase} >Vider la base de donnée</button>
            </div>
        </div>
    );
}
export default GestionBDD;
