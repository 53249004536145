import './Desinscription.css';
import { useParams } from "react-router-dom"; 
import { useContext } from 'react'; // Import useContext
import { useEffect,useState } from 'react';
import { api } from './api';
import { DataContext } from './DataContext';

function Desinscription() {
    const params = useParams();
    const { data } = useContext(DataContext);
    const [prix,setPrix]=useState({});

    const handleSubmit = async () => {
        try {
            const res = await api.delete(`/desinscription/${params.id}`);
            if (res) {
                window.location.href = '/confirmation/desinscription';
            }
        } catch (error) {
            console.error(error);
            alert('Une erreur est survenue lors de la désinscription.'); // User feedback
        }
    }

    useEffect(() => {
        if (data && data.prix) {
	    setPrix ({
       	        enscien: data.prix.enscien === "0" ? false : data.prix.enscien,
                invit: data.prix.invit === "0" ? false : data.prix.invit,
                exte: data.prix.exte === "0" ? false : data.prix.exte
	    })
        }
    }, [data]);

    return (
    <div>
        {(prix.enscien || prix.invit || prix.exte) &&
            <div className="Desinscription-header">
                <h3 className="Desinscription-h3">La désinscription automatique n'est pas possible pour les événements payants</h3>
                <h3 className="Desinscription-h3">Si vous souhaitez vous désinscrire envoyez un mail à bde@ensci.com</h3>
            </div>
        }

        {!(prix.enscien || prix.invit || prix.exte) && 
            <div className='Desinscription'>
                <header className='Desinscription-header-1'>
                    <p>Êtes-vous sûr de vouloir vous désinscrire ?</p>
                </header>
                <button className={`Desinscription-button`} onClick={handleSubmit}>
                    Me désinscrire
                </button>
            </div>
        }
    </div>
    )
}

export default Desinscription;

