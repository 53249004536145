import {api} from '../api'
import './AdminPage.css';

function InfosSoireePage({handleAlert}){

    const handleSubmit = async (event) => {
        event.preventDefault();
        const nom = document.getElementById('Formulaire-Nom').value;
        const infos = document.getElementById('Formulaire-Infos').value;
        const lieu = document.getElementById('Formulaire-Lieu').value;
        const date = document.getElementById('Formulaire-date').value;
        const date_debut = document.getElementById('Formulaire-date-debut').value;
        const heure_debut = document.getElementById('Formulaire-heure-debut').value;
        const date_fin = document.getElementById('Formulaire-date-fin').value;
        const heure_fin = document.getElementById('Formulaire-heure-fin').value;

        const formaData= {
            nom,
            infos,
            lieu,
            date,
            date_debut,
            heure_debut,
            date_fin,
            heure_fin
        }

        try {
            const response = await api.post('/admin/formulaire/post/infos',formaData);
        
            if (response.status === 200) {
                console.log('Données téléchargées avec succès');
                handleAlert("Informations modifiées avec succès");
            } else {
                handleAlert("Une erreur est survenue");
                console.error(response.status);
            }
        } catch (error) {
            console.error(error);
        }
        
    };

    return (
        <form onSubmit={handleSubmit} className="Formulaire-form">
            <input id="Formulaire-Nom" placeholder="Nom de l'évènement" className="Formulaire-input-event" />
            <p style={{fontSize : '60%'}}>aide : <code>&lt;mark&gt; <mark>votre texte surligné</mark> &lt;/mark&gt;; &lt;strong&gt; <strong>votre texte en gras</strong> &lt;/strong&gt;; &lt;em&gt; <em>votre texte en italique</em> &lt;/em&gt;; &lt;a href="votre lien"&gt;votre texte qui cache le lien&lt;/a&gt;</code></p>
            <textarea id="Formulaire-Infos" placeholder="Informations sur l'évènement" className="Formulaire-input-event" rows="5"/>
            <input id="Formulaire-Lieu" placeholder="Lieu de l'évènement" className="Formulaire-input-event" />
            <label>Date de l'évènement : </label>
            <input type="date" placeholder="Date de l'évènement" id="Formulaire-date" className="Formulaire-input"/>
            <label>Date de début des inscriptions :</label>
            <input type="date" placeholder="Date de début des inscriptions" id="Formulaire-date-debut" className="Formulaire-input"/>
            <input type="time" id="Formulaire-heure-debut" className="Formulaire-input"/>
            <label>Date de fin des inscriptions :</label>
            <input type="date" placeholder="Date de fin des inscriptions" id="Formulaire-date-fin" className="Formulaire-input"/>
            <input type="time" id="Formulaire-heure-fin" className="Formulaire-input"/>
            <button className='AdminPage-button-valid' type="submit">Valider</button>
        </form>
    );
}
export default InfosSoireePage;
