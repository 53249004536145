import React, { useState } from 'react';
import './AdminPage.css';
import {pages} from './resources'
import PublicPage from './PublicPage';
import InfosSoireePage from './InfosSoireePage';
import StylePage from './StylePage';
import AddDJToList from './AddDJToList.js';
import GestionBDD from './GestionBDD.js';
import Croix from '../Croix.js';


function AdminPage({ changeCurrentPage}) {

  const [sousPages,setSousPages]=useState(5)
  const [showAlert, setShowAlert] = useState("");

  const handleAlert = (message) => {
      setShowAlert(message);
  };

  const closeAlert = () => {
      setShowAlert("");
  };

  const changeSousPage = (page) => {
    setSousPages(page);
  };

  return (
    <div className="ListPage-main">
      <header className="List-header">
        <button className='Bouton-Retour' onClick={() => changeCurrentPage(pages.HOMEPAGE)} alt='retour' id="homePage-return">
          {"↖"}
        </button>
        <h1 className='ListPage-h1'>L'évènement</h1>
        <Croix />
      </header>
      <div className="Formulaire">
        <header className="Formulaire-header">
          <button className={`AdminPage-button ${sousPages===5 ? 'page-active' : ''}`} onClick={() => changeSousPage(5)}>
                base de donnée
          </button>
          <button className={`AdminPage-button ${sousPages===1 ? 'page-active' : ''}`} onClick={() => changeSousPage(1)}>
              évènement
          </button>
          <button className={`AdminPage-button ${sousPages===2 ? 'page-active' : ''}`}onClick={() => changeSousPage(2)}>
                prix
          </button>
          <button className={`AdminPage-button ${sousPages===3 ? 'page-active' : ''}`} onClick={() => changeSousPage(3)}>
                style
          </button>
          <button className={`AdminPage-button ${sousPages===4 ? 'page-active' : ''}`} onClick={() => changeSousPage(4)}>
                line UP
          </button>
        </header>
        <main className="Formulaire-main">
          {sousPages===1 && <InfosSoireePage handleAlert={handleAlert}/>}
          {sousPages===2 && <PublicPage handleAlert={handleAlert}/>}
          {sousPages===3 && <StylePage handleAlert={handleAlert} /> }
          {sousPages===4 && <AddDJToList handleAlert={handleAlert}/>}
          {sousPages===5 && <GestionBDD handleAlert={handleAlert}/>}
          {showAlert!=="" && <CustomAlert message={showAlert} onClose={closeAlert} />}
        </main>
      </div>
    </div>
  );
}

function CustomAlert({ message, onClose }) {
  return (
      <div className="custom-alert-overlay">
          <div className="custom-alert-box">
              <p>{message}</p>
              <button className="AdminPage-button-add" onClick={onClose}>OK</button>
          </div>
      </div>
  );
}

export default AdminPage

