import './Desinscription.css';
import {useParams} from "react-router-dom";
import CircleSVG from './annimConfirmation'
import Croix from './Croix';

function ConfirmationPage() {
    
    const params = useParams();
    let text = "";
    if(params.info === "desinscription"){
        text = "Vous êtes bien désinscrit."
    }
    else if(params.info === "inscription"){
        text = "Merci de vous être inscrit!"
    }
    else{
        window.location.href = '/';
    }    

    const handleRetourClick = () => {
        // Rediriger vers la page d'accueil lorsque le bouton Retour est cliqué
        window.location.href = '/';
      };
    
      return (
        <div className="Desinscription">
          <header className="Desinscription-header">
            <CircleSVG isValid={true}/>
            <h1 className='h1-Confirmation'>{text}</h1>
          </header>
          <button onClick={handleRetourClick} className="Desinscription-button" id="button">
            <b>Retour</b>
          </button>
          <Croix id="croix1"/>
          <Croix id="croix2"/>
          <Croix id="croix3"/>
          <Croix id="croix4"/>
        </div>
      );
    }
    
export default ConfirmationPage;

    
    
    
    
    
