import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import MainPage from './MainPage';
import AppAdmin from './admin/App';
import AppVigile from './vigile/App';
import SignUpPage from './SignUpPage';
import Desinscription from './Desinscription';
import ErrorPage from './error-page' ;
import ConfirmationPage from './ConfirmationPage';
import PaymentStatus from './PaymentStatus';
import AboutPage from './AboutPage';
import PolitiqueConfidentialite from './legal/PolitiqueConfidentialite';
import CGV from './legal/CGV';
import CGU from './legal/CGU';
import SumUpWidget from './SumUpWidget';
import { DataProvider } from './DataContext';

import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";

const root = ReactDOM.createRoot(document.getElementById('root'));

const router = createBrowserRouter([
  {
    path: "/",
    element: <MainPage/>,
    errorElement : <ErrorPage/>
  },
  {
    path: "/inscription",
    element: <SignUpPage/>,
  },
  {
    path: "/admin",
    element: <AppAdmin/>,
  },
  {
    path: "/vigile",
    element: <AppVigile/>,
  },
  {
    path: "/desinscription/:id",
    element: <Desinscription/>,
  },
  {
    path: "/confirmation/:info",
    element: <ConfirmationPage/>,
  },
  {
    path: "/paiement/statut/:id",
    element: <PaymentStatus/>,
  },
  {
    path : "/paiementPage/:id",
    element:<SumUpWidget/>
  },
  {
    path: "/about/",
    element: <AboutPage/>,
  },
  {
    path: "/confidentialite/",
    element: <PolitiqueConfidentialite/>,
  },
  {
    path: "/cgv",
    element: <CGV/>,
  },
  {
    path: "/cgu/",
    element: <CGU/>,
  }
]);

root.render(
  <React.StrictMode>
    <DataProvider>
      <RouterProvider router={router} />
    </DataProvider>
  </React.StrictMode>
);
reportWebVitals();
