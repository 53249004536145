import {useState} from "react"
import {api} from '../api'
import './AdminPage.css';

function PublicPage({handleAlert}) {

    const [clientType, setClientType] = useState({
        enscien: false,
        invit: false,
    });

    const [customFields, setCustomFields] = useState([]); // Pour gérer les champs personnalisés

    const handleCheckboxChange = (event) => {
        const { name, checked } = event.target;
        setClientType(prevState => ({
          ...prevState,
          [name]: checked,
        }));
    };

    const handleAddField = () => {
      // Limiter le nombre de champs à 3
      if (customFields.length < 3) {
          setCustomFields([...customFields, { name: '', price: '' }]); // Ajoute un champ vide
      } else {
          handleAlert("Vous ne pouvez ajouter que 3 champs personnalisés maximum");
      }
  };

    const handleCustomFieldChange = (index, field, value) => {
        const updatedFields = [...customFields];
        updatedFields[index][field] = value;
        setCustomFields(updatedFields);
    };

    const handleRemoveField = (index) => {
        const updatedFields = [...customFields];
        updatedFields.splice(index, 1); // Supprime le champ à l'index donné
        setCustomFields(updatedFields);
    };

    const handleSubmit = async (event) => {
        event.preventDefault();
        const places = document.getElementById('Formulaire-Places').value;
        const nbInvit = clientType.invit ? document.getElementById('Formulaire-Invit').value : 0;

        const prix = {
          enscien: clientType.enscien ? document.getElementById('Formulaire-Prix-enscien').value : false,
          invit: clientType.invit ? document.getElementById('Formulaire-Prix-invit').value : false,
      };
      
      // Fusionne les champs statiques et personnalisés en un seul objet
      const prixFusionne = {
          ...prix,
          ...customFields.reduce((acc, field) => {
              acc[field.name] = field.price;
              return acc;
          }, {})
      };
      
      const formaData = {
          places,
          nbInvit,
          prix: prixFusionne
      };
      
        try {
            const response = await api.post('/admin/formulaire/post/publics', formaData);

            if (response.status === 200) {
                console.log('Données téléchargées avec succès');
                handleAlert("Modifications effectuées avec succès");
            } else {
                handleAlert("Une erreur est survenue");
                console.error(response.status);
            }
        } catch (error) {
            console.error(error);
        }
    };

    return (
        <form onSubmit={handleSubmit} className="Formulaire-form">
            <input type='number' id="Formulaire-Places" placeholder="Nombre de places" className="Formulaire-input" />
            <br />
            <label>
              <input
                type="checkbox"
                name="enscien"
                checked={clientType.enscien}
                onChange={handleCheckboxChange}
              />
              Ensciens
            </label>
            {clientType.enscien && <input type='number' step="0.01" id="Formulaire-Prix-enscien" placeholder="Prix de la place"  className="Formulaire-input" />}
            <br />
            <label>
              <input
                type="checkbox"
                name="invit"
                checked={clientType.invit}
                onChange={handleCheckboxChange}
              />
              Invités
            </label>
            {clientType.invit && 
                <div className="invit-div">
                    <input id="Formulaire-Invit" type='number' placeholder="Nombre par étudiant" className="Formulaire-input"/>
                    <input type='number' step="0.01" id="Formulaire-Prix-invit" placeholder="Prix de la place" className="Formulaire-input" />
                </div>
            }
            <br />
            
            <button type="button" className='AdminPage-button-add' onClick={handleAddField}>+ Ajouter un champ personnalisé</button>
            <div className="custom-input">
               
                {customFields.map((field, index) => (
                    <div key={index} className="custom-field">
                        <input
                            type="text"
                            value={field.name}
                            onChange={(e) => handleCustomFieldChange(index, 'name', e.target.value)}
                            placeholder="Nom du champ"
                            className="Formulaire-input"
                        />
                        <input
                            type="number"
                            step="0.01"
                            value={field.price}
                            onChange={(e) => handleCustomFieldChange(index, 'price', e.target.value)}
                            placeholder="Prix"
                            className="Formulaire-input"
                        />
                        <button type="button" className='AdminPage-button-empty' id='button-empty-smaller' onClick={() => handleRemoveField(index)}>Supprimer</button>
                    </div>
                ))}
            </div>
            <br />

            <button className='AdminPage-button-valid' type="submit">Valider</button>
        </form>
    );
}

export default PublicPage;
