import './PasswordPage.css';
import { useState } from 'react';
import { FaEye, FaEyeSlash } from 'react-icons/fa';

function PasswordPage({ passwordValid }) {
  const [errorMessage, setErrorMessage] = useState('');
  const [passwordVisible, setPasswordVisible] = useState(false);
  const [password, setPassword] = useState('');

  const handlePasswordChange = (e) => {
    setPassword(e.target.value);
  };

  const togglePasswordVisibility = () => {
    setPasswordVisible(!passwordVisible);
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    const formData = { password };
    const res = await passwordValid(formData);
    if (res !== 200) {
      setErrorMessage('Mot de passe incorrect');
    }
  };

  return (
    <div className="Password-Main">
      <h1 className='Password-h1'>Connexion</h1>
      <p className="error-message">{errorMessage || '\u00A0'}</p>
      <form onSubmit={handleSubmit} className="password-form">
        <div className="password-input-container">
          <input
            id="password"
            type={passwordVisible ? 'text' : 'password'}
            value={password}
            onChange={handlePasswordChange}
            placeholder="Mot de passe"
            className={`Password-input ${passwordVisible ? 'visible-password' : 'hidden-password'}`} // Classes pour changer de typo
          />
          <button
            type="button"
            onClick={togglePasswordVisibility}
            className="Password-toggle-visibility"
          >
            {passwordVisible ? <FaEyeSlash /> : <FaEye />}
          </button>
        </div>
        <button type="submit" id="password-button" className="Password-button">
          Valider
        </button>
      </form>
    </div>
  );
}

export default PasswordPage;
