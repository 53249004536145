import '../SignUpPage.css';
import Croix from '../Croix';


function CGV() {

  
  return (
    <div className="SignUp">
      <div className="SignUp-header">
        <a href="/inscription" className="SignUp-retour"> {"↖"}</a>
        <Croix/>
      </div>
        <div className='politique-confidentialite'>
            <h1>Conditions Générales de Vente (CGV) </h1>
            <br/>
            <p>
            <b>Préambule : </b> <br/><br/>
            Les présentes Conditions Générales de Vente (CGV) régissent la vente de billets pour les événements organisés par l'association "BUREAU DES ÉLÈVES DES ATELIERS" via son site internet. <br/>
            </p>
            <br/>
            <p>
            <b>1. Identification de l'association</b> <br/><br/> 
                Nom : BUREAU DES ÉLÈVES DES ATELIERS<br/>
                Siège social : 48 rue Saint Sabin, 75011 Paris<br/>
                Numéro de SIRET : 85065169600013 <br/>
                Adresse e-mail : bde@ensci.com
            </p>
            <br/>
            <p>
            <b>2. Objet</b> <br/><br/> 
                Les présentes CGV définissent les conditions dans lesquelles l'association "BUREAU DES ÉLÈVES DES ATELIERS" propose la vente de billets pour ses événements.<br/>
            </p>
            <br/>
            <p>
            <b>3. Tarifs et moyens de paiement</b>    <br/><br/> 
                Le prix des billets est indiqué en euros, toutes taxes comprises (TTC). <br/>
                Aucune majoration ou frais supplémentaire n'est appliqué au prix du billet, les frais de transaction via SUMUP étant pris en charge par l'association.<br/>
                Le paiement s'effectue exclusivement par carte bancaire via la plateforme SUMUP, qui garantit la sécurité des transactions.
            </p>
            <br/>
            <p>
            <b>4. Envoi et livraison des billets</b> <br/><br/> 
                Les billets sont envoyés par e-mail à l'adresse renseignée par l'utilisateur lors de l'achat.<br/>
                L'utilisateur est responsable de l'exactitude de cette information. En cas d'erreur dans l'adresse e-mail, l'association ne pourra être tenue responsable.
            </p>
            <br/>
            <p>
            <b>5. Adhésion à l'association</b> <br/><br/> 
                En achetant un billet, l'utilisateur devient automatiquement adhérent de l'association "BUREAU DES ÉLÈVES DES ATELIERS" pour une durée de six (6) mois.<br/>
                Cette adhésion est incluse dans le prix du billet et permet, entre autres, la consommation de boissons alcoolisées sur place lors de l'événement.
            </p>
            <br/>
            <p>
            <b>6. Politique de remboursement</b> <br/><br/> 
                Aucun remboursement ne sera accordé, sauf dans le cas où l'accès à l'événement est impossible pour des raisons directement imputables à l'association (ex. : annulation de l'événement par l'organisateur).<br/> 
                En dehors de cette situation, toute vente est ferme et définitive.
            </p>
            <br/>
            <p>
            <b>7. Garantie d'accès</b> <br/><br/> 
                L'achat d'un billet ne garantit pas l'accès à l'événement.<br/> 
                L'association "BUREAU DES ÉLÈVES DES ATELIERS" se réserve le droit de refuser l'entrée à toute personne pour des raisons légitimes, notamment en cas de non-respect des règles de l'événement, des conditions sanitaires en vigueur, ou si la capacité maximale du lieu a été atteinte.<br/> 
                Dans ces cas, aucun remboursement ne sera accordé.
            </p>
            <br/>
            <p>
            <b>8. Âge minimum requis</b><br/><br/> 
            Les événements organisés par l'association "BUREAU DES ÉLÈVES DES ATELIERS" sont réservés aux personnes de 18 ans et plus.<br/> 
            L'association se réserve le droit de refuser l'entrée à toute personne ne pouvant justifier de son âge lors de l'événement.<br/> 
            Aucune dérogation ne sera accordée à cette règle.
            </p>
            <br/>
            <p>
            <b>9. Protection des données personnelles</b> <br/><br/> 
                Les données collectées (nom, prénom, adresse e-mail, numéro de téléphone) sont nécessaires pour la gestion des ventes de billets et des adhésions à l'association.<br/> 
                Elles sont conservées pendant une durée de six (6) mois après la fin de l'événement. Passé ce délai, elles seront supprimées. <br/> 
                Ces données ne seront en aucun cas cédées ou vendues à des tiers.
            </p>
            <br/>
            <p>
            <b>10. Responsabilité de l'association</b> <br/><br/> 
                L'association "BUREAU DES ÉLÈVES DES ATELIERS" décline toute responsabilité en cas d'indisponibilité temporaire du site internet ou d'interruptions techniques empêchant l'achat de billets.<br/> 
                L'association ne pourra être tenue responsable de tout dommage ou préjudice lié à l'utilisation du site.
            </p>
            <br/>
            <p>
            <b>11. Droit applicable et litiges</b> <br/><br/> 
                Les présentes CGV sont soumises au droit français.<br/> 
                En cas de litige, et après échec de toute tentative de résolution à l'amiable, les tribunaux français seront seuls compétents.
            </p>
            <br/>
            <p>
            <b>12. TVA</b><br/><br/> 
            Conformément à la législation en vigueur, l'association "BUREAU DES ÉLÈVES DES ATELIERS" bénéficie d'une franchise en base de TVA en raison de son faible chiffre d'affaires.<br/>
            À ce titre, aucune TVA n'est applicable sur les ventes de billets.
            </p>
            <br/>
            <p>
                Les CGV ont été mise à jour le 31/10/2024.
            </p>
        </div>

      <div className="SignUp-header">
        <Croix/>
        <Croix/>
      </div>
    </div>
  );
}

export default CGV;







