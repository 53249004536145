import '../SignUpPage.css';
import Croix from '../Croix';


function PolitiqueConfidentialite() {

  
  return (
    <div className="SignUp">
      <div className="SignUp-header">
        <a href="/inscription" className="SignUp-retour"> {"↖"}</a>
        <Croix/>
      </div>
        <div className='politique-confidentialite'>
            <h1>Politique de Confidentialité</h1>
            <br/>
            <p>
                Cette politique de confidentialité régit la manière dont "BUREAU DES ÉLÈVES DES ATELIERS" collecte, utilise, conserve et divulgue les informations collectées auprès des utilisateurs (ci-après dénommés "Utilisateurs") du site web bde.ensci.com (ci-après dénommé "Site"). <br/>
                Cette politique de confidentialité s'applique au Site et à tous les produits et services proposés par "BUREAU DES ÉLÈVES DES ATELIERS".
            </p>
            <br/>
            <p>
            <b>Collecte et utilisation des informations personnelles</b> <br/><br/>
            Lors de l'inscription à un événement organisé par "BUREAU DES ÉLÈVES DES ATELIERS", nous pouvons collecter des informations personnelles auprès des Utilisateurs de différentes manières, y compris, mais sans s'y limiter, lorsque les Utilisateurs visitent notre Site, s'inscrivent à un événement, remplissent un formulaire et en relation avec d'autres activités, services, fonctionnalités ou ressources que nous mettons à leur disposition sur notre Site. <br/>
            Les Utilisateurs peuvent être invités à fournir leur nom, prénom, adresse e-mail, numéro de téléphone et autres informations pertinentes nécessaires à leur inscription à un événement.<br/>
            Nous collectons ces informations personnelles uniquement dans le but de gérer l'inscription à l'événement et de communiquer avec les participants. <br/>
            Nous ne conservons pas ces informations après la tenue de l'événement, sauf si cela est nécessaire pour se conformer à une obligation légale ou réglementaire.
            </p>
            <br/>
            <p>
            <b>Protection des informations</b> <br/><br/>
            Nous adoptons des pratiques appropriées de collecte, de stockage et de traitement des données et des mesures de sécurité pour protéger contre tout accès non autorisé, altération, divulgation ou destruction de vos informations personnelles, nom d'utilisateur, mot de passe, informations de transaction et données stockées sur notre Site.
            </p>
            <br/>
            <p>
                <b>Partage d'informations personnelles</b><br/><br/>
                Nous ne vendons, n'échangeons ni ne louons les informations personnelles des Utilisateurs à des tiers.<br/>
                Nous ne partageons pas vos informations personnelles avec des fournisseurs de services tiers. <br/>
                Toutes les informations que vous nous fournissez sont utilisées uniquement dans le cadre de la gestion de votre inscription à nos événements et pour communiquer avec vous à cet égard.
            </p>
            <br/>
            <p>
                <b>Conformité à la loi</b><br/><br/>
                Nous nous engageons à respecter les lois et réglementations applicables en matière de protection des données.<br/>
                Si nous recevons une demande ou une exigence légale nous obligeant à divulguer des informations personnelles, nous ne le ferons que dans la mesure où nous sommes légalement tenus de le faire et après avoir pris des mesures raisonnables pour nous assurer que la demande est légitime.
            </p>
            <br/>
            <p>
            <b>Votre acceptation de ces termes</b><br/><br/>
            En utilisant ce Site, vous signifiez votre acceptation de cette politique et des conditions d'utilisation.<br/>
            Si vous n'acceptez pas cette politique, veuillez ne pas utiliser notre Site. <br/>
            Votre utilisation continue du Site après la publication de modifications apportées à cette politique sera considérée comme votre acceptation de ces modifications.
            </p>
            <br/>
            <p>
                <b>Contactez-nous</b> <br/> <br/>    
                Si vous avez des questions concernant cette politique de confidentialité, les pratiques de ce site, ou vos interactions avec ce site, veuillez nous contacter à : <br/>
                <br/>BUREAU DES ÉLÈVES DES ATELIERS
                <br/>48 Rue Saint Sabin, 75011 Paris
                <br/>bde@ensci.com
            </p>
            <br/>
            <p>
                Cette politique de confidentialité a été mise à jour le 31/10/2024.
            </p>
        </div>

      <div className="SignUp-header">
        <Croix/>
        <Croix/>
      </div>
    </div>
  );
}

export default PolitiqueConfidentialite;

