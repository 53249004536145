import React from 'react';
import './MaintenancePage.css'
import Croix from './Croix.js'
import LogoBDE from './LogoBDE'

function MaintenancePage() {
  return (
    <div className="MaintenancePageMain">
      <LogoBDE className="MaintenancePag-Logo"/>
      <h1>Nous sommes actuellement en maintenance</h1>
      <p>Veuillez nous excuser pour la gêne occasionnée.</p>
      <p>Revenez nous voir bientôt !</p>
      <Croix id="croix1"/>
      <Croix id="croix2"/>
      <Croix id="croix3"/>
      <Croix id="croix4"/>
    </div>
  );
}

export default MaintenancePage;
