import React, { useEffect, useState } from 'react';
import { pages } from './resources.js';
import './VigilePage.css'
import '../MainPage.css'
import {api} from '../api.js'
import LogoBDE from '../LogoBDE.js'
import { FaUsers } from "react-icons/fa";
import { MdQrCodeScanner } from "react-icons/md";

function VigilePage({changeCurrentPage}) {
  const handleSubmit = (event, page) => {
    event.preventDefault();
    changeCurrentPage(page);
  };

  const handleRetourClick = () => {
     window.location.href = '/';
   };

  const [entre, setEntre] = useState(0);
  const [inscrit, setInscrit] = useState(0);
  const [entreP100, setEntreP100] = useState(0)

  const getList = async () => {
    try {
      const response = await api.get('/admin/list');
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  const getListAsync = async () => {
    try {
      const newData = await getList();
      const nbInscrit = newData.length;
      const nbEntre = newData.filter(item => item.entre === true).length;
      if(nbInscrit >0){
        setEntre(nbEntre)
        setInscrit(nbInscrit)
        setEntreP100(nbEntre * 100 / nbInscrit)
        if(nbEntre < 10 && nbEntre > 0 && nbInscrit>75){
          setEntreP100(8)
        }
      } else {
        setEntreP100(0)
      }
      console.log(entreP100)
    } catch (error) {
      console.error('Erreur de récupération des données :', error);
    }
  };

  useEffect(() => {
    getListAsync();
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, []);
  

  return (
    <div className='VigilePage-Main'>
      <header className='App-header'>
        <LogoBDE/>
        <h1 className='VigilePage-Title'>Vigile only</h1>
      </header>
      {entreP100 >= 70 && <div className='jaugeBackground'> <div className='jauge' style={{width: `${entreP100}%`}}> {entre} entrés</div></div>}
      {entreP100 > 30 && entreP100 < 70 && <div className='jaugeBackground'> <div className='jauge' style={{width: `${entreP100}%`}}> {entre} entrés</div><div>{inscrit-entre} restants</div>   </div>}
      {entreP100 >= 0 && entreP100 <= 30 && <div className='jaugeBackground'> <div className='jauge' style={{width: `${entreP100}%`}}></div> <div>{inscrit-entre} restants</div> </div>}
      <div className='VigilePage-Button-div'>
        <button onClick={(event)=>handleSubmit(event, pages.QRCODESCANNER)} className='VigilePage-Button' id='vigile-button1'><MdQrCodeScanner alt="QR Code" className='svg-qrcode'/>Scan QR code</button>
        <button onClick={(event) => handleSubmit(event, pages.LISTPAGE)} className='VigilePage-Button' id='vigile-button2'><FaUsers alt="liste" className='svg-qrcode'/>Liste des inscrits</button>
      </div>
     </div>
  );
}

export default VigilePage;
