import { api } from '../api';
import "./ClientStatusPage.css"

const ClientStatusPage = ({message,valide,nom, nomEnsci,changeStatusVisibility,id, type}) => {

    const handleSubmit = async (event) => {
        event.preventDefault();
        try{
            const response = await api.post('/admin/isEnter/'+id)
            if(response.status===200){
                changeStatusVisibility(false);
            }
            else {
                console.error(response.status)
            }
        }catch(error){ 
            console.error(error)
        }  
    };
    
    const handleVisibility = () => {
        changeStatusVisibility(false);
	};

    return (
        <div className='StatusPage-Main'>
            <h1 className={`h1-Status ${valide ? 'rouge' : 'vert'}`}>
	        {message}
	    </h1>
	    <h2>{nom}</h2>
            {type==="invit" ? <h4>Élève référent : {nomEnsci} </h4> : <h4>Type : {type}</h4>}

            {valide === false  &&<div className='StatusPage-div-button'>
                <button className='StatusPage-confirm' onClick={(event) => handleVisibility(event)}>Annuler</button>
                <button className='StatusPage-confirm' id='status-valid-confirm' onClick={(event) => handleSubmit(event)}>Valider la place</button>
            </div>}
            {valide &&
		<div className='StatusPage-div-button'>
                	<button className='StatusPage-confirm' onClick={(event) => handleVisibility(event)}>Retour</button>
           	</div>
	    }
        </div>
    )  
}

export default ClientStatusPage
