const pages ={
    PASSWORDPAGE:0,
    HOMEPAGE:1,
    EVENT:2,
    LISTPAGE:3,
    NEWPOSTERPAGE:4,
    VOLUNTEERPAGE:5,
    DEBUGPAGE : 6,
    EMAILPAGE:7
  }

export {pages}

