import React, { useState, useContext } from 'react';
import './AdminPage.css';
import './VolunteerPage.css';
import './SendEmailToAll.css';
import { api } from '../api';
import logo from '../logo.svg';
import { DataContext } from '../DataContext';
import Croix from '../Croix.js';
import { pages } from './resources'

function SendEmailToAll({ changeCurrentPage }) {
    const { data } = useContext(DataContext);

    const [subject, setSubject] = useState('');
    const [text, setText] = useState('');
    const [html, setHtml] = useState('');

    const [showAlert, setShowAlert] = useState("");
    const [errorMessage, setErrorMessage] = useState('...');
    const [loading, setLoading] = useState(false);

    const handleAlert = (message) => {
        setShowAlert(message);
    };

    const closeAlert = () => {
        setShowAlert("");
    };

    const handleSubmit = async (e) => {
        e.preventDefault();
        setLoading(true);
        
        if (!subject || !text || !html) {
            setErrorMessage("Champs vide");
            setLoading(false);
            return;
        }

        try {
            const about = { subject, text, html };

            // Appeler l'API pour envoyer l'email
            const response = await api.post('/sendEmailToAll', about);

            if (response.status === 200) {
                handleAlert('E-mails envoyés avec succès !');
                setSubject("");
                setText("");
                setHtml("");
                setLoading(false);
            } else {
                handleAlert("Une erreur est survenue lors de l'envoie");
            }
        } catch (error) {
            handleAlert('Erreur : ' + (error.response?.data?.message || error.message));
        } finally {
            setLoading(false);
        }
    };

    return (
        <div className="Volunteer">
            {showAlert !== "" && <CustomAlert message={showAlert} onClose={closeAlert} />}
            {loading &&
            <div className="loader">
                <img src={logo} className="logo-loader" alt="logo" />
            </div>
            }
            <div className="Volunteer-header">
                <button className='Bouton-Retour' onClick={() => changeCurrentPage(pages.HOMEPAGE)} alt='retour' id="homePage-return">
                    {"↖"}
                </button>
                <Croix />
            </div>
            <div className='Volunteer-title'>
                <h1 className="Volunteer-h1">Email à la liste de diffusion</h1>
                <hr className="Volunteer-separator"></hr>
            </div>
            {data && 
            <main className="Volunteer-main">
                <form onSubmit={handleSubmit} className="Email-form">
                    <input
                        placeholder="Objet" 
                        className="Volunteer-input"
                        type="text"
                        value={subject}
                        onChange={(e) => setSubject(e.target.value)}
                    />
                    <textarea
                        placeholder="Texte" 
                        className="Volunteer-input"
                        value={text}
                        onChange={(e) => setText(e.target.value)}
                    />
                    <textarea
                        placeholder="HTML" 
                        className="Volunteer-input"
                        value={html}
                        onChange={(e) => setHtml(e.target.value)}
                    />
                    <div className="error-message" style={{ opacity: errorMessage === '...' ? 0 : 1 }}>
                        {errorMessage}
                    </div>
                    <button type="submit" className="Volunteer-button"> Valider </button>
                </form>
                <hr className="SignUp-separator2"></hr>
            </main>
            }
            <div className="Volunteer-header">
                <Croix />
                <Croix />
            </div>
        </div>
    );
}

function CustomAlert({ message, onClose }) {
    return (
      <div className="custom-alert-overlay">
        <div className="custom-alert-box">
          <p>{message}</p>
          <button className="AdminPage-button-add" onClick={onClose}>OK</button>
        </div>
      </div>
    );
}

export default SendEmailToAll;
