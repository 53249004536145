import React, { useEffect, useState } from 'react';
import '../admin/ListPage.css';
import { api } from '../api';
import { pages } from './resources';
import * as XLSX from 'xlsx';

function ListPage({ changeCurrentPage }) {
  const [list, setList] = useState([]);
  const [validpage, setValidPage] = useState(false);
  const [validItem, setValidItem] = useState(null);
  const [sortBy, setSortBy] = useState('chronological'); // Default sorting by 'chronological'
  const [filteredList, setFilteredList] = useState([]); // Liste filtrée
  const [searchQuery, setSearchQuery] = useState(''); // Champ de recherche

  const getList = async () => {
    try {
      const response = await api.get('/admin/list');
      return response.data;
    } catch (error) {
      console.error(error);
      return [];
    }
  };

  
  const getListAsync = async () => {
    try {
      const newData = await getList();
      setList(newData);
      
      const sortedData = sortList(newData, sortBy); // Applique le tri basé sur le critère actuel
      const filtered = applySearch(sortedData, searchQuery); // Applique le filtrage actuel
      setFilteredList(filtered); // Initialise filteredList avec les données triées et filtrées
      console.log(newData)
    } catch (error) {
      console.error('Erreur de récupération des données :', error);
    }
  };  

  const sortList = (data, criteria) => {
    let sortedData = [...data];
    
    if (criteria === 'nom') {
      sortedData.sort((a, b) => a.nom.localeCompare(b.nom));
    } else if (criteria === 'type') {
      sortedData.sort((a, b) => {
        const referentA = (a.nomEnsci && a.prenomEnsci) ? a.nomEnsci.toUpperCase() : 'ZZZ';
        const referentB = (b.nomEnsci && b.prenomEnsci) ? b.nomEnsci.toUpperCase() : 'ZZZ';
        return referentA.localeCompare(referentB);
      });
    }
    // Pour 'chronological', on ne trie pas les données, on garde l'ordre original
    return sortedData;
  };

  const handleSortChange = (event) => {
    const selectedCriteria = event.target.value;
    setSortBy(selectedCriteria);
  
    const sortedData = sortList([...list], selectedCriteria); // Trie la liste complète
    const filtered = applySearch(sortedData, searchQuery); // Applique le filtrage actuel
    setFilteredList(filtered); // Met à jour filteredList avec les données triées et filtrées
  };

  // Appliquer le filtrage basé sur la recherche actuelle
  const applySearch = (data, query) => {
    if (query === '') {
      return data; // Si la recherche est vide, renvoyer la liste entière triée
    }
    
    return data.filter((item) => {
      const fullName = `${item.nom} ${item.prenom}`.toLowerCase();
      const referentName = `${item.prenomEnsci || ''} ${item.nomEnsci || ''}`.toLowerCase();

      // Vérification si le nom, prénom ou élève référent correspondent à la recherche
      return (
        fullName.includes(query.toLowerCase()) ||
        referentName.includes(query.toLowerCase())
      );
    });
  };

  // Gérer la recherche
  const handleSearchChange = (e) => {
    const query = e.target.value;
    setSearchQuery(query);

    // Toujours appliquer la recherche sur la liste triée selon le critère actuel
    const sortedData = sortList([...list], sortBy); // D'abord, on trie la liste selon le critère sélectionné
    const filtered = applySearch(sortedData, query); // Ensuite, on applique le filtrage
    setFilteredList(filtered); // Mise à jour de la liste filtrée avec la recherche appliquée
  };
  
  useEffect(() => {
    getListAsync();
  }, []);
  

  const handleEnter = (item) => {
    setValidItem(item);
    setValidPage(true);
  };

  const handleCancel = () => {
    setValidItem(null);
    setValidPage(false);
  };

  const handleConfirmation = async () => {
    try {
      const res = await api.post('/admin/isEnter/'+validItem.id)
      if (res) {
        console.log('entrée validée');
      }
    } catch (error) {
      console.error(error);
    } finally {
      setValidItem(null);
      setValidPage(false);
      getListAsync();
    }
  };

  return (
    <div className="ListPage-main">
      {validpage && (
        <div className="ModificationPage">
          <h3 className="ConfirmationH3">Valider l'entrée de {validItem.prenom} {validItem.nom} ?</h3>
          <button className="ListPage-confirm-delete" onClick={handleCancel}>Annuler</button>
          <button className="ListPage-confirm-delete" id="valid-confirm" onClick={handleConfirmation}>Valider</button>
        </div>
      )}
      <header className="List-header">
        <button className='Bouton-Retour' onClick={() => changeCurrentPage(pages.HOMEPAGE)} alt='retour' id="homePage-return">
          {"↖"}
        </button>
        <h1 className='ListPage-h1'>Les inscrits</h1>
      </header>
      
      <div className="SortingDropdown">
        <input
          type="text"
          value={searchQuery}
          onChange={handleSearchChange}
          placeholder="Rechercher un participant..."
          className="SearchInput"
        />
        <h4 className="List-h4">Total : {filteredList.length}</h4>
        <select id="sort-select" value={sortBy} onChange={handleSortChange}>
          <option value="chronological">Chronologique</option>
          <option value="nom">Nom</option>
          <option value="type">Type</option>
        </select>
      </div>

      <table className="ListPage-table">
        <thead>
          <tr>
            <th>#</th>
            <th>Nom</th>
            <th>Prénom</th>
            <th>Numéro</th>
            <th>Email</th>
            <th>Type</th>
            <th>Action</th>
          </tr>
        </thead>
        <tbody>
          {filteredList.map((item, index) => (
            <tr key={index}>
              <td data-label="#"> {index + 1} </td>
              <td data-label="Nom"> {item.nom} </td>
              <td data-label="Prénom"> {item.prenom} </td>
              <td data-label="Numéro"> {item.num} </td>
              <td data-label="Email"> {item.email} </td>
              <td data-label="Type"> 
                {item.prenomEnsci && item.nomEnsci ? `${item.prenomEnsci} ${item.nomEnsci}` :  `${item.type}`} 
              </td>
              <td data-label="Action">
                {item.entre ? <span className='Entre-valide'> entrée validée </span> : <button className='ListPage-delete-button' onClick={() => handleEnter(item)}>Valider</button> }
              </td>
            </tr>
          ))}
        </tbody>
      </table>
    </div>
  );
}

export default ListPage;
