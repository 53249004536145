import axios from "axios";
const apiKey=process.env.REACT_APP_API_KEY;
const apiUrl=process.env.REACT_APP_API_URL;
const api = axios.create({
    baseURL:apiUrl,
    timeout:15000,
    headers: {
        "x-api-key":apiKey,
        "Custom-Header":"header",
        "Content-Type":"application/json",
    },
    withCredentials:true
});

api.interceptors.request.use(
  (config) => {
    const token = localStorage.getItem('token'); // Récupérer le token depuis localStorage
    // Vérifier si l'URL contient "/admin" pour n'ajouter le token que sur les pages admin
    if (token && config.url.startsWith('/admin/')) {
      config.headers['Authorization'] = `Bearer ${token}`;
    }

    return config;
  },
  (error) => Promise.reject(error)
);

export {api}
