import React, { useState, useContext, useEffect } from 'react';
import './AdminPage.css';
import './VolunteerPage.css';
import { api } from '../api';
import logo from '../logo.svg';
import { DataContext } from '../DataContext';
import Croix from '../Croix.js';
import { pages } from './resources'

function VolunteerPage({ changeCurrentPage}) {
  const { data } = useContext(DataContext);

  const [showAlert, setShowAlert] = useState("");
  const [errorMessage, setErrorMessage] = useState('...');
  const [loading, setLoading] = useState(false);

  // State pour les champs d'input
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [numTel, setNumTel] = useState("");
  const [email, setEmail] = useState("");

  const [countTrue,setCountTrue] = useState(1)
  const [type, setType] = useState("")

  useEffect(() => {
    if (data) {
      const trueKeys = Object.entries(data.prix)
        .filter(([key, value]) => value !== false)
        .map(([key]) => key);

      setCountTrue(Object.values(data.prix).filter(value => value !== false).length);

      if(trueKeys.length===1){
        setType(trueKeys)
      }
    }
  }, [data]);

  const handleSelectChange = (event) => {
    setType(event.target.value);
  };

  const handleAlert = (message) => {
      setShowAlert(message);
  };

  const closeAlert = () => {
    setShowAlert("");
  };

  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    
    const formData = {
      nom: lastName,
      prenom: firstName,
      num: numTel,
      email: email,
      type: type
    }

    try {
      if (!numTel || !firstName || !lastName || !email) {
        setErrorMessage("Champs vide");
        setLoading(false);
        return;
      }

      if (formData.type === "") {
        setErrorMessage("Erreur type");
        setLoading(false);
        return;
      }

      if (formData.type==="invit"){
        formData.nomEnsci = document.getElementById('SignUp-last-ensci').value;
        formData.prenomEnsci = document.getElementById('SignUp-first-ensci').value;
        if (formData.nomEnsci==="" || formData.prenomEnsci===""){
          setErrorMessage("Champs vide")
          setLoading(false);
          return
        }
      }

      const res = await api.post('/admin/client/post/', formData);


      if (res.status === 200) {
        setErrorMessage('...');
        handleAlert("Place ajoutée avec succès !");
        // Réinitialise les champs après succès
        setFirstName("");
        setLastName("");
        setNumTel("");
        setEmail("");
        setLoading(false);
      } else {
        handleAlert("Une erreur est survenue");
        console.error(res.status);
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data) {
        setErrorMessage(error.response.data);
      }
      setLoading(false);
    }
  };

  return (
    <div className="Volunteer">
      {showAlert !== "" && <CustomAlert message={showAlert} onClose={closeAlert} />}
      {loading &&
        <div className="loader">
          <img src={logo} className="logo-loader" alt="logo" />
        </div>
      }
      <div className="Volunteer-header">
        <button className='Bouton-Retour' onClick={() => changeCurrentPage(pages.HOMEPAGE)} alt='retour' id="homePage-return">
          {"↖"}
        </button>
        <Croix />
      </div>
      <div className='Volunteer-title'>
        <h1 className="Volunteer-h1">Inscription admin</h1>
        <hr className="Volunteer-separator"></hr>
      </div>
      {data &&
        <main className="Volunteer-main">
	  <select id="type-select" onChange={handleSelectChange} value={type}>
	      <option value="volunteer">Place bénévole</option>
              {Object.entries(data.prix).map(([typeKey, value]) =>
                  value && (
                      <option value={typeKey} key={typeKey}>
                          {typeKey === "enscien" && "Place enscien.ne"}
                          {typeKey === "invit" && "Place invité.e par enscien.ne"}
                          {typeKey !== "enscien" && typeKey !== "invit" && `Place ${typeKey}`}
                      </option>
                  )
              )}
          </select>
          <form onSubmit={handleSubmit} className="Volunteer-form">
            <input
              id="Volunteer-first"
              placeholder="Prénom"
              className="Volunteer-input"
              value={firstName} 
              onChange={(e) => setFirstName(e.target.value)} 
            />
            <input 
              id="Volunteer-last" 
              placeholder="Nom" 
              className="Volunteer-input" 
              value={lastName} 
              onChange={(e) => setLastName(e.target.value)} 
            />
            <input 
              id="Volunteer-numTel" 
              type="tel" 
              placeholder="Numéro de téléphone" 
              className="Volunteer-input" 
              pattern="^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$"
              value={numTel} 
              onChange={(e) => setNumTel(e.target.value)} 
            />
            <input 
              id="Volunteer-email" 
              type="email" 
              placeholder="Email" 
              pattern="^((?![+]).)*$"  
              required 
              className="Volunteer-input" 
              value={email} 
              onChange={(e) => setEmail(e.target.value)} 
            />
            <div className="error-message" style={{ opacity: errorMessage === '...' ? 0 : 1 }}> 
              {errorMessage} 
            </div>
            <button type="submit" className="Volunteer-button"> Valider </button>  
          </form>
          <hr className="SignUp-separator2"></hr>
        </main>
      }
      
      <div className="Volunteer-header">
        <Croix />
        <Croix />
      </div>
    </div>
  );
}

function CustomAlert({ message, onClose }) {
  return (
    <div className="custom-alert-overlay">
      <div className="custom-alert-box">
        <p>{message}</p>
        <button className="AdminPage-button-add" onClick={onClose}>OK</button>
      </div>
    </div>
  );
}

export default VolunteerPage;

