import React, { useState, useEffect } from 'react';
import './annimConfirmation.css'

const CircleSVG = ({isValid}) => {
  const [circles, setCircles] = useState([]);
  const [showSmiley2,setShowSmiley2]=useState(false);
  const [compteur,setCompteur]=useState(0)

  const heart = [
    { cx: 350, cy: 294.6 },
    { cx: 317.4, cy: 251.1 },
    { cx: 267.1, cy: 233.1 },
    { cx: 221.3, cy: 260.1 },
    { cx: 211.7, cy: 312.9 },
    { cx: 231.6, cy: 362.1 },
    { cx: 269, cy: 400.1 },
    { cx: 309.5, cy: 436.9 },
    { cx: 350, cy: 473.7 },
    { cx: 390.4, cy: 436.9 },
    { cx: 430.9, cy: 400.1 },
    { cx: 468.4, cy: 362.1 },
    { cx: 488.3, cy: 312.9 },
    { cx: 478.7, cy: 260.1 },
    { cx: 432.9, cy: 233.1 },
    { cx: 382.5, cy: 251.1 },
  ];

  const smiley = [
    { cx: 348.4, cy: 196.6 },
    { cx: 350, cy: 503.4 },
    { cx: 289.76, cy: 208.58 },
    { cx: 408.64, cy: 491.42 },
    { cx: 240.16, cy: 242.09 },
    { cx: 458.24, cy: 457.91 },
    { cx: 207.17, cy: 292.03 },
    { cx: 491.23, cy: 407.97 },
    { cx: 195.8, cy: 350.8 },
    { cx: 502.6, cy: 349.2 },
    { cx: 207.78, cy: 409.44 },
    { cx: 490.62, cy: 290.56 },
    { cx: 241.29, cy: 459.04 },
    { cx: 457.11, cy: 240.96 },
    { cx: 291.23, cy: 492.03 },
    { cx: 407.17, cy: 207.97 },
  ];

  const smiley2=[
    { cx: 308.14, cy: 304.09 },
    { cx: 395.11, cy: 304.09 },
    { cx: 372.52, cy: 419.01 },
    { cx: 413.64, cy: 394.29 },
    { cx: 284.16, cy: 394.29 },
    { cx: 324.68, cy: 419.01 },
  ]

  const cross = [
    { cx: 231.6, cy: 238.4, r: 31 },
    { cx: 269, cy: 276.4, r: 31 },
    { cx: 309.5, cy: 313.2, r: 31 },
    { cx: 350, cy: 350, r: 31 },
    { cx: 390.4, cy: 313.2, r: 31 },
    { cx: 430.9, cy: 276.4, r: 31 },
    { cx: 468.4, cy: 238.4, r: 31 },
    { cx: 231.6, cy: 461.6, r: 31 },
    { cx: 272, cy: 424.8, r: 31 },
    { cx: 312.5, cy: 388, r: 31 },
    { cx: 350, cy: 350, r: 31 },
    { cx: 350, cy: 350, r: 31 },
    { cx: 387.4, cy: 388, r: 31 },
    { cx: 427.9, cy: 424.8, r: 31 },
    { cx: 468.4, cy: 461.6, r: 31 }
  ];

  useEffect(() => {
    const interval = setInterval(() => {

      if (isValid){
        if (compteur % 12 === 0) {
          setCircles(heart);
        }
        
        if (compteur % 12 === 6) {
          setCircles(smiley);
        }

        if (compteur%12===7){
          setShowSmiley2(true)
        }

        if (compteur%12===11){
          setShowSmiley2(false)
        }
        
        setCompteur(prevCompteur => prevCompteur + 1); // Mettre à jour le compteur
      }else{
        setCircles(cross);
        setShowSmiley2(false)
      }
    }, 500);
  
    // Nettoyer l'intervalle lorsque le composant est démonté
    return () => clearInterval(interval);
  }, [compteur,isValid]); // Ajouter compteur comme dépendance
  

  return (
    <svg viewBox="0 0 700 700" className='svg-confimation'>
      {circles.map((circle, index) => (
        <circle className='circle-confirmation' key={index} cx={circle.cx} cy={circle.cy} r="31" fill="white" />
      ))}
      {smiley2.map((circle, index) => (
        <circle className= {showSmiley2 ? 'smiley2 active' :'smiley2'} key={index} cx={circle.cx} cy={circle.cy} r="31" fill="white" />
      ))}
    </svg>
  );
};

export default CircleSVG;
