import './QrCodeScanner.css';
import { api } from '../api';
import { pages } from './resources';
import ClientStatusPage from './ClientStatusPage';
import React, { useEffect, useRef, useState } from 'react';
import QrScanner from 'qr-scanner';

const QrCodeScanner = ({ changeCurrentPage }) => {
  const videoRef = useRef(null);
  const [message, setMessage] = useState("");
  const [clientId, setClientId] = useState("");
  const [StatusVisibility, setStatusVisibility] = useState(false);
  const [valide, setValide] = useState(false);
  const [nom, setNom] = useState("");
  const [nomEnsci, setNomEnsci] = useState("");
  const [type, setType] = useState("");

  const changeStatusVisibility = (value) => {
    setStatusVisibility(value);
  };

  const getClientStatus = async (data) => {
    try {
      const response = await api.get('/admin/entre/' + data);
      setClientId(data);
      setMessage(response.data.entre ? "Place déjà scannée" : "Place valide");
      setStatusVisibility(true);
      setValide(response.data.entre);
      setNom(response.data.prenom + " " + response.data.nom);
      setNomEnsci(response.data.prenomEnsci + " " + response.data.nomEnsci);
      setType(response.data.type);
    } catch (error) {
      console.error(error);
      setMessage("Place non reconnue... Réessayer ou chercher manuellement avec la liste");
    }
  };

  

  useEffect(() => {
    const qrScanner = new QrScanner(videoRef.current, result => handleScanSuccess(result), {
      highlightCodeOutline: true,
      preferredCamera: 'environment'
    });

    qrScanner.setInversionMode('both');

    qrScanner.start().catch(err => console.error('Could not start the scanner', err));

    return () => {
      qrScanner.stop();
      qrScanner.destroy();
    };
  }, []);

  const handleScanSuccess = async (result) => {
    if (result) {
      console.log('QR code detected:', result.data);
      getClientStatus(result.data);
    }
  };



  return (
    <div className='QR-Main'>
      <header className="List-header">
        <button className='Bouton-Retour' onClick={() => changeCurrentPage(pages.HOMEPAGE)} alt='retour' id="homePage-return">
          {"↖"}
        </button>
        <h1 className='ListPage-h1'>Scanner QR Code</h1>
      </header>
      
      <div className='video-container'>
        <video ref={videoRef} className='QR-video'></video>
      </div>

      {message && message!=='Place valide' && message!=='Place déjà scannée' && (
        <div className="Message-window">
          <p>{message}</p>
        </div>
      )}
      {StatusVisibility && <ClientStatusPage id={clientId} valide={valide} nom={nom} nomEnsci={nomEnsci} message={message} type={type} changeStatusVisibility={changeStatusVisibility} />}
     
    </div>
  );
};

export default QrCodeScanner;