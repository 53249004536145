import {useState,useEffect} from 'react';
import './Desinscription.css';
import {useParams} from "react-router-dom";
import CircleSVG from './annimConfirmation'
import Croix from './Croix.js';
import { api } from "./api.js";

function PaymentStatus() {

    const params = useParams();
    const id =params.id;

    const [status,setStatus]=useState("");

    useEffect(() => {
	console.log(id);
        // If id is empty, do nothing
        if (!id) {
            return;
        }

        const fetchPaymentStatus = async () => {
            try {
                const response = await api.get('/payment/getStatus/'+id);
		console.log(response.data);
                setStatus(response.data.status);
            } catch (error) {
                // Handle error and display a message
                if (error.response) {
                    console.log(`Error: ${error.response.data.error}`);
                } else {
                    console.log('Error: Unable to fetch payment status.');
                }
                setStatus(''); // Reset status on error
            }
        };

        // Call the fetch function
        fetchPaymentStatus();
    }, [id]);

    const handleRetourClick = () => {
        // Rediriger vers la page d'accueil lorsque le bouton Retour est cliqué
        window.location.href = '/';
      };

      return (
        <div className="Desinscription">
            <header className="Desinscription-header">
                {status!=="" && <CircleSVG isValid={status==="PAID"}/>}
                {status==="PAID" && <h1 className='h1-Confirmation'>Merci de vous être inscrit !</h1>}
                {(status==="FAILED" || status==="EXPIRED") && <h1 className='h1-Confirmation'>Le paiement a échoué !</h1>}
            </header>
            <button onClick={handleRetourClick} className="Desinscription-button" id="button">
            <b>Retour</b>
          </button>
          <Croix id="croix1"/>
	  <Croix id="croix2"/>
          <Croix id="croix3"/>
          <Croix id="croix4"/>
        </div>
      );
    }

export default PaymentStatus;
