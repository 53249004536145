import { useState, useEffect } from "react";
import { api } from "./api.js";
import LogoAnnim from "./LogoAnnim2.js";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import "./AboutPage.css"

function AboutPage() {
    const [posters, setPosters] = useState([]);

    const getPostersAsync = async () => {
        try {
            const newData = await api.get('/postersList/');
            setPosters(newData.data); 
        } catch (error) {
            console.error('Erreur de récupération des données :', error);
        }
    };

    useEffect(() => {
        try {
            getPostersAsync();
        } catch (error) {
            console.error(error);
        }
    }, []);

    const settings = {
        dots: true,
        infinite: true,
        speed: 500,
        slidesToShow: 1,
        slidesToScroll: 1,
        adaptiveHeight: true
    };

    const sortedPosters = [...posters].sort((a, b) => {
        return new Date(a.date) - new Date(b.date);
    }).reverse();

    const [windowWidth, setWindowWidth] = useState(window.innerWidth);

    useEffect(() => {
        const handleResize = () => {
            setWindowWidth(window.innerWidth);
        };

        window.addEventListener('resize', handleResize);

        return () => {
            window.removeEventListener('resize', handleResize);
        };
    }, []);

    const scrollToTarget = () => {
        const target = document.getElementById('about_h2');
        console.log(target);
    
        const targetPosition = target.getBoundingClientRect().top + window.scrollY;
        const newPosition = targetPosition - 10; 
    
        window.scrollTo({
            top: newPosition,
            behavior: 'smooth',
        });
    }

    return (
        <div className="About-Main">
            <header className="About-header">
                <a href="/" className="About-retour">
                    {"↖"}
                </a>
                <a className='About-retour' href="https://www.instagram.com/p/CbgNYgAIyOQ/?img_index=1">
                    {"Insta"}
                </a>
            </header>
            <LogoAnnim/>
            <p className="About-Credit-site">Site web par <br/> <a className="a-credit" href="https://julesherve.com">Jules Hervé</a> & <a className="a-credit" href="https://sachasabatier.com">Sacha Sabatier</a></p>
            <buton onClick={scrollToTarget}>
                <h1 className="arrow">
                    {windowWidth < 1000 ? "↓↓↓" : "↓↓↓↓↓"}
                </h1>
            </buton>
            <h2 id="about_h2" className="about_h2">Évènements organisés par le BDE</h2>
            <hr className="Separator"></hr>
            <div className="poster-Container">
                {sortedPosters && sortedPosters.length > 0 && (
                    sortedPosters.map((poster, index) => (
                        <div key={index}className="poster-div" >
                            <div >
                                <img
                                    className="img-carousel"
                                    src={`https://bde.ensci.com/serveur/posters/${poster.imageName}`}
                                    alt={poster.imageName}
                                />
                            </div>
                            <p className="About-Credit">© {poster.credit}</p>
                        </div>
                    ))
                
                )}
            </div>
            <hr className="Separator"></hr>
            <p className="About-Credit">© <span className="credit-typo-tiny">TINY</span> by Jack Halten Fahnestock. Distributed by velvetyne.fr</p>
            <p className="About-Credit">© <span className="credit-typo-unbounded">Unbounded</span> by Google</p>
        </div>
    );
}

export default AboutPage;
