import React, { useState, useContext, useEffect } from 'react';
import './SignUpPage.css';
import { api } from './api';
import logo from './logo.svg';
import { DataContext } from './DataContext';
import Croix from './Croix.js';



function SignUpPage() {

  const { data } = useContext(DataContext);

  const formatDate = (dateString) => {
    const options = { day: '2-digit', month: '2-digit', year: 'numeric' };
    return new Date(dateString).toLocaleDateString(undefined, options);
  };

  function formatDate2(dateString) {
    // Convertit la chaîne de caractères en objet Date
    const dateParts = dateString.split('/');
    const day = parseInt(dateParts[0], 10);
    const month = parseInt(dateParts[1], 10) - 1; // Les mois sont indexés à partir de zéro dans JavaScript
    const year = parseInt(dateParts[2], 10);
    const date = new Date(year, month, day);

    // Tableaux de noms de jours et de mois
    const daysOfWeek = ['dimanche', 'lundi', 'mardi', 'mercredi', 'jeudi', 'vendredi', 'samedi'];
    const months = ['janvier', 'février', 'mars', 'avril', 'mai', 'juin', 'juillet', 'août', 'septembre', 'octobre', 'novembre', 'décembre'];

    // Récupère le nom du jour de la semaine, le jour, le mois et l'année
    const dayOfWeek = daysOfWeek[date.getDay()];
    const dayOfMonth = date.getDate();
    const monthOfYear = months[date.getMonth()];
    const yearValue = date.getFullYear();

    // Retourne la date formatée
    return `${dayOfWeek} ${dayOfMonth} ${monthOfYear} ${yearValue}`;
  }

  const [countTrue,setCountTrue] = useState(3)
  const [type, setType] = useState("")

  useEffect(() => {
    if (data) {
      const trueKeys = Object.entries(data.prix)
        .filter(([key, value]) => value !== false)
        .map(([key]) => key);

      setCountTrue(Object.values(data.prix).filter(value => value !== false).length);

      if(trueKeys.length===1){
        setType(trueKeys)
      }
    }
  }, [data]);

  const [errorMessage, setErrorMessage] = useState('...');

  const [loading, setLoading] = useState(false);

  const handleSelectChange = (event) => {
    setType(event.target.value);
};


  const handleSubmit = async (event) => {
    event.preventDefault();
    setLoading(true);
    const formData={
      nom: document.getElementById('SignUp-last').value,
      prenom : document.getElementById('SignUp-first').value,
      num : document.getElementById('SignUp-numTel').value,
      email : document.getElementById('SignUp-email').value,
      type : type
    }


    try {

      if (formData.num ==="" || formData.prenom==="" || formData.nom==="" || formData.email===""){
        setErrorMessage("Champs vide")
        setLoading(false);
        return
      }
      if(countTrue>1 && formData.type ===""){
        setErrorMessage("Champs vide")
        setLoading(false);
        return
      }
      if (formData.type==="invit"){
        formData.nomEnsci = document.getElementById('SignUp-last-ensci').value;
        formData.prenomEnsci = document.getElementById('SignUp-first-ensci').value;
        if (formData.nomEnsci==="" || formData.prenomEnsci===""){
          setErrorMessage("Champs vide")
          setLoading(false);
          return
        }
      }
      const res = await api.post('/client/post/', formData);
      if(res){
	setLoading(false);
        window.location.href = res.data;
        setErrorMessage('...');
      }
    } catch (error) {
      console.error(error);
      if (error.response && error.response.data){
        setErrorMessage(error.response.data);
      }
      setLoading(false);
    }
  };

  return (
    <div className="SignUp">
      {loading &&
        <div className="loader">
          <img src={logo} className="logo-loader" alt="logo" />*
        </div>
      }
      <div className="SignUp-header">
        <a href="/" className="SignUp-retour"> {"↖"}</a>
        <Croix/>
      </div>
      <div className='SignUp-title'>
        <h1 className="SignUp-h1">Réservation</h1>
        <hr className="SignUp-separator"></hr>
      </div>
      {data && 
        <div className='SignUp-grid'>
          <div className='recap-resa'>
            <img src="https://bde.ensci.com/serveur/image" className='SignUp-affiche' alt="affiche soiree"></img>
            {data.date && data.lieu && <p className='recap' >{formatDate2(formatDate(data.date))}<br/> {data.lieu}</p>}
            {type && 
              <p className='recap prix'>
                1 place - {data.prix[type]}€
              </p>
            }
            {type==="" &&  <p className='recap prix' >1 place</p>}
          </div>
          
          <main className="SignUp-main">
              <select id="type-select" onChange={handleSelectChange} value={type}>
                {countTrue > 1 && <option value="">je suis ...</option>}
                {Object.entries(data.prix).map(([typeKey, value]) =>
                  value && ( // Vérifie si le type est présent dans les données
                    <option value={typeKey} key={typeKey}>
                      {typeKey === "enscien" && "je suis un.e étudiant.e de l'ENSCI"}
                      {typeKey === "invit" && "je suis un.e invité.e d'une personne de l'ENSCI"}
                      {typeKey !== "enscien" && typeKey !== "invit" && ` Tarif ${typeKey} - ${value}€`}
                    </option>
                  )
                )}
              </select>
            <form onSubmit={handleSubmit} className="SignUp-form">
              <input id="SignUp-first" placeholder="Prénom" className="SignUp-input" />
              <input id="SignUp-last" placeholder="Nom" className="SignUp-input" />
              <input id="SignUp-numTel" type="tel" placeholder="Numéro de téléphone" className={`SignUp-input`} pattern="^((\+\d{1,3}(-| )?\(?\d\)?(-| )?\d{1,5})|(\(?\d{2,6}\)?))(-| )?(\d{3,4})(-| )?(\d{4})(( x| ext)\d{1,5}){0,1}$"/>
              <input id="SignUp-email" type="email" placeholder="Email" pattern="^((?![+]).)*$"  required   className={`SignUp-input `} />
              {type==="invit" && <p id="SignUp-p-ensci">je suis invité par : </p> }
              {type==="invit" && <input id="SignUp-first-ensci" placeholder="Prénom" className="SignUp-input"/>}
              {type==="invit" && <input id="SignUp-last-ensci" placeholder="Nom" className="SignUp-input"/> }    
              <div className={`error-message ${type === "invit" ? 'error-message-grid' : ''}`} style={{ opacity: errorMessage === '...' ? 0 : 1 }}> {errorMessage} </div>
              <button type="submit" className={`SignUp-button ${type === "invit" ? 'SignUp-button-grid' : ''}`}> Réserver ma place </button>  
            </form>
          </main>
          <div className="SignUp-text">
            <hr className="SignUp-separator2"></hr>
            <p>Vous recevrez votre place nominative par email, qui sera à présenter à l’entrée de l’événement. La sécurité peut être amener à demander une pièce d’identité en complément.
	          <br/> Pour en savoir plus sur la manière dont sont traitées vos données, lisez notre <a className="SignUp-text-a" href="/confidentialite">Politique de Confidentialité</a>
            <br/> En utilisant ce site vous acceptez nos <a className="SignUp-text-a" href="/cgv">CGV</a> & <a className="SignUp-text-a" href="/cgu">CGU</a>
            <br/><br/><a className="SignUp-text-a" href="mailto:bde@ensci.com?subject=[URGENT][BDE.ENSCI.COM]%20Je%20rencontre%20un%20problème%20pour%20prendre%20ma%20place%20à%20l'évènement"> J'ai un problème</a></p>
          </div>
        </div>
      }
      <div className="SignUp-header">
        <Croix/>
        <Croix/>
      </div>
    </div>
  );
}

export default SignUpPage;

