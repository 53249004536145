import '../SignUpPage.css';
import Croix from '../Croix';


function CGU() {

  
  return (
    <div className="SignUp">
      <div className="SignUp-header">
        <a href="/inscription" className="SignUp-retour"> {"↖"}</a>
        <Croix/>
      </div>
        <div className='politique-confidentialite'>
            <h1>Conditions Générales d'Utilisation (CGU)</h1>
            <br/>
            <p>
            <b>1. Objet des CGU </b> <br/><br/>
            Les présentes Conditions Générales d'Utilisation (CGU) définissent les règles d'utilisation du site internet de l'association "BUREAU DES ÉLÈVES DES ATELIERS". <br/>
            En accédant au site, l'utilisateur accepte de se conformer aux présentes CGU.
            </p>
            <br/>
            <p>
            <b>2. Accès au site</b> <br/><br/> 
            Le site de l'association "BUREAU DES ÉLÈVES DES ATELIERS" est accessible à tout utilisateur disposant d'un accès à internet.<br/>
            L'accès à certaines sections du site peut être réservé aux membres de l'association.
            </p>
            <br/>
            <p>
            <b>3. Propriété intellectuelle</b> <br/><br/> 
            Tous les éléments du site (textes, images, logos, graphismes) sont la propriété exclusive de l'association "BUREAU DES ÉLÈVES DES ATELIERS", sauf mention contraire. <br/>
            Toute reproduction, distribution, ou utilisation non autorisée de ces éléments est interdite sans accord préalable.
            </p>
            <br/>
            <p>
            <b>4. Utilisation du site</b>    <br/><br/> 
            L'utilisateur s'engage à utiliser le site de manière conforme aux lois et règlements en vigueur.<br/>
            Il s'interdit tout acte susceptible de nuire au bon fonctionnement du site, notamment en accédant aux parties réservées ou en perturbant les services offerts par l'association.
            </p>
            <br/>
            <p>
            <b>5. Protection des données personnelles</b> <br/><br/> 
            Les données personnelles collectées sur le site (nom, prénom, adresse e-mail, numéro de téléphone) sont traitées conformément à la politique de confidentialité de l'association.<br/>
            L'utilisateur peut consulter cette politique pour connaître ses droits en matière de protection des données.
            </p>
            <br/>
            <p>
            <b>6. Responsabilité</b> <br/><br/> 
            L'association "BUREAU DES ÉLÈVES DES ATELIERS" met tout en œuvre pour assurer la disponibilité et le bon fonctionnement du site, mais ne saurait être tenue responsable en cas de dysfonctionnement ou d'interruption des services, ainsi que de tout dommage direct ou indirect résultant de l'accès ou de l'utilisation du site.
            </p>
            <br/>
            <p>
            <b>7. Modification des CGU</b> <br/><br/> 
            L'association "BUREAU DES ÉLÈVES DES ATELIERS" se réserve le droit de modifier à tout moment les présentes CGU.<br/>
            Les modifications seront effectives dès leur mise en ligne sur le site.
            </p>
            <br/>
            <p>
            <b>8. Droit applicable</b> <br/><br/> 
            Les présentes CGU sont soumises au droit français. <br/>
            En cas de litige, les tribunaux français seront seuls compétents
            </p>
            <br/>
            <p>
                Les CGU ont été mise à jour le 31/10/2024.
            </p>
        </div>

      <div className="SignUp-header">
        <Croix/>
        <Croix/>
      </div>
    </div>
  );
}

export default CGU;







