import React, { useState } from 'react';
import "./Croix.css"

function Croix({id}) {
  const [isHovered, setIsHovered] = useState(false);

  const handleHover = () => {
    setIsHovered(!isHovered);
  };

  return (
    <div id={id} onMouseEnter={handleHover} onMouseLeave={handleHover}>
      <svg
        viewBox="0 0 40 40"
        className={isHovered ? 'hovered croix_svg' : 'croix_svg'}
      >
        <g className="vertical">
          <circle className="croix-cercle" cx="20" cy="14.3" r="6.2" />
        </g>
        <g className="vertical">
          <circle className="croix-cercle" cx="20" cy="25.7" r="6.2" />
        </g>
        <g className=" horizontal">
          <circle className="croix-cercle" cx="25.7" cy="20" r="6.2" />
        </g>
        <g className=" horizontal">
          <circle className="croix-cercle" cx="14.3" cy="20" r="6.2" />
        </g>
      </svg>
    </div>
  );
}

export default Croix;
